import { useEffect, useState } from "react";
import { Steady } from "steady-sdk";
import "./App.css";

const ethers = require("ethers");
const steadyAddress = "0x3be084d857068701f74422E90066622d28C9c2d2";
const wrappedAddress = "0x67fBC3929C16900B1cD9B16740711098e377A1Fd";
const erc20abi = ["function balanceOf(address account) view returns (uint256)"];
const externalRPC = "https://eth-goerli.g.alchemy.com/v2/demo";

function App() {
  const [actionButtonText, setActionButtonText] = useState("Connect wallet");
  const [wrapActive, setWrapActive] = useState(true);
  const [connected, setConnected] = useState(false);
  const [steadySDK, setSteadySDK] = useState(null);
  const [fromValue, setFromValue] = useState(0);
  const [toValue, setToValue] = useState(0);
  const [wrappedContract, setWrappedContract] = useState(null);
  const [wrappedBalance, setWrappedBalance] = useState(0);
  const [steadyBalance, setSteadyBalance] = useState(0);
  const [connectedAddress, setConnectedAddress] = useState(0);
  const [balanceTaskRunning, setBalanceTaskRunning] = useState(0);
  const [waitingForAction, setWaitingForAction] = useState(false);

  async function connect() {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    let accounts = await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();
    let contract = new Steady(steadyAddress, externalRPC, signer);
    const wrappedContractInstance = new ethers.Contract(wrappedAddress, erc20abi, provider);
    setWrappedContract(wrappedContractInstance);
    setConnectedAddress(accounts[0]);

    if (contract) {
      setSteadySDK(contract);
      setConnected(true);
    }

    provider.on("accountsChanged", function (accounts) {
      setConnectedAddress(accounts[0]);
    });
  }
  async function addTokenToWallet(tokenAddress, tokenSymbol) {
    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: 6, // The number of decimals in the token
            //image: tokenImage, // A string url of the token logo
          },
        },
      });

      if (wasAdded) {
        console.log("Token successfully added");
      } else {
        console.log("Token add was denied");
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function doAction() {
    if (!connected) {
      connect();
      return;
    }

    if (wrapActive) {
      wrap();
    } else {
      unwrap();
    }
  }
  async function wrap() {
    if (steadySDK) {
      setWaitingForAction(true);
      steadySDK.wrapTokens(fromValue * 1000000, (resp) => {
        setWaitingForAction(false);
        updateBalances();
        console.log(resp);
      });
    } else {
      console.error("Steady SDK not initialised");
    }
  }
  async function unwrap() {
    if (steadySDK) {
      setWaitingForAction(true);
      steadySDK.unwrapTokens(fromValue * 1000000, (resp) => {
        setWaitingForAction(false);
        updateBalances();
        console.log(resp);
      });
    } else {
      console.error("Steady SDK not initialised");
    }
  }
  async function validateInputs() {
    if (connected && fromValue > 0) {
      if (wrapActive) {
        steadySDK.steadyToTokens(fromValue*1000000, (tokenAmount) => {
          setToValue(tokenAmount/1000000);
        });
      } else {
        steadySDK.tokensToSteady(fromValue*1000000, (steadyAmount) => {
          setToValue(steadyAmount/1000000);
        });
      }
    }
  }
  async function updateBalances() {
    if (!connected) {
      setTimeout(updateBalances, 10000);
      return;
    }
    try {
      const wrappedBalanceInWei = await wrappedContract.balanceOf(connectedAddress);
      setWrappedBalance(wrappedBalanceInWei / 1000000);

      await steadySDK.balanceOf(connectedAddress, (balance) => {
        setSteadyBalance(balance / 1000000);
      });
    } catch (error) {
      console.error("Not able to fetch balances");
    }

    setTimeout(updateBalances, 10000);
  }

  useEffect(() => {
    if (balanceTaskRunning) {
      return;
    } else {
      setBalanceTaskRunning(true);
      setTimeout(updateBalances, 10000);
    }
  }, [balanceTaskRunning]);

  useEffect(() => {
    //console.log("Wrapped balance: " + wrappedBalance)
    validateInputs();
  }, [wrappedBalance]);

  useEffect(() => {
    //console.log("Steady balance: " + steadyBalance)
    validateInputs();
  }, [steadyBalance]);

  useEffect(() => {
    //console.log("Connected" + connected);
    updateBalances();
  }, [connected]);

  useEffect(() => {
    let actionButtonText = "Connect wallet";
    if (connected) {
      actionButtonText = wrapActive ? "Wrap" : "Unwrap";
    }
    validateInputs();
    setActionButtonText(actionButtonText);
  }, [connected, wrapActive]);

  useEffect(() => {
    validateInputs();
  }, [fromValue]);

  const handleFromValueChange = (e) => {
    setFromValue(e.target.value);
  };
  const handleToValueChange = (e) => {
    setToValue(e.target.value);
  };

  return (
    <div className="App">
      <div className="networkAddButton" onClick={() => addTokenToWallet(steadyAddress, "stUSD")}>
        Add stUSD to wallet
      </div>
      <div className="networkAddButton" onClick={() => addTokenToWallet(wrappedAddress, "wstUSD")}>
        Add wstUSD to wallet
      </div>
      <div className="wrapperContainer">
        <div className="toggleContainer">
          <div className={`toggleOption ${wrapActive ? " toggleSelected" : ""}`} onClick={() => setWrapActive(true)}>
            Wrap
          </div>
          <div className={`toggleOption ${wrapActive ? "" : " toggleSelected"}`} onClick={() => setWrapActive(false)}>
            Unwrap
          </div>
        </div>
        <div className="inputContainer">
          <div className="inputLabel">You send</div>
          <div className="inputInnerContainer">
            <input
              className="numInput"
              type="number"
              value={fromValue}
              onChange={handleFromValueChange}
              placeholder="0"
            ></input>
            <div className="currencyLabel">{`${wrapActive ? "stUSD" : "wstUSD"}`}</div>
          </div>
          <div className="balanceLabel">{`Your balance: ${wrapActive ? steadyBalance : wrappedBalance}`}</div>
        </div>
        <div className="inputContainer">
          <div className="inputLabel">You receive</div>
          <div className="inputInnerContainer">
            <input
              className="numInput"
              type="number"
              value={toValue}
              onChange={handleToValueChange}
              placeholder="0"
            ></input>
            <div className="currencyLabel">{`${wrapActive ? "wstUSD" : "stUSD"}`}</div>
          </div>
          <div className="balanceLabel">{`Your balance: ${wrapActive ? wrappedBalance : steadyBalance}`}</div>
        </div>
        <div className={`wrapButton ${waitingForAction ? " rotating" : ""}`} onClick={doAction}>
          {waitingForAction ? "waiting for your confirmation ..." : actionButtonText}
        </div>
      </div>
    </div>
  );
}

export default App;
